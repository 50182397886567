<div class="row">
    <div class="col-md-6">
        <div class="inner-tabs">
            <h2>Understanding Imports</h2>
            <p>Use our batch file import feature to batch add or update existing fireworks.
            Fireworks are matched and updated based on their part number.</p>
            <div class="save-custom-btn">
                <mat-chip-list aria-label="Fish selection">
                    <mat-chip color="warn" selected>Required</mat-chip>
                    <mat-chip color="accent" selected>Suggested</mat-chip>
                  </mat-chip-list>
            </div>
            <p>Please find the CSV file format below. You can also download a <a href="https://ignite-bucket-s3.s3.amazonaws.com/app/sample.csv">sample.csv</a> import file.</p>
            <ul>
                <li>
                    <span class="name red">name</span>
                    <span class="name-text">The firework name.</span>
                </li>
                <li>
                    <span class="name red">FIREWORK TYPE</span>
                    <span class="name-text">The type of firework. For example, Comets. Download our <a href="#">FireworksTypeList.csv</a> .</span>
                </li>
                <li>
                    <span class="name red">PART NUMBER</span>
                    <span class="name-text">Your unique firework identifier (part number, sku, etc...). For example, COMET123.</span>
                </li>
                <li>
                    <span class="name yellow">DESCRIPTION</span>
                    <span class="name-text">A few sentence description of the firework.</span>
                </li>
                <li>
                    <span class="name yellow">DURATION</span>
                    <span class="name-text">The firework duration in seconds. Enter a number only, no need for "s" or "seconds".</span>
                </li>
                <li>
                    <span class="name yellow">YOUTUBE URL</span>
                    <span class="name-text">The video URL. This can be a YouTube URL such as <a href="#">http://www.youtube.com/watch?v=QGJuMBdaqIw</a> or a local video URL such as <a href="#">http://www.yourcompany.com/myfirework.mp4.</a></span>
                </li>
                <li>
                    <span class="name yellow">IMAGE URL</span>
                    <span class="name-text">The full image URL. For example, http://www.myfireworks.com/  badmother.jpg</span>
                </li>
                <li>
                    <span class="name yellow">WEBSITE URL</span>
                    <span class="name-text">The website firework page URL. For example, http://www.samsfireworks.com/  500gcakes/bigboomer.html</span>
                </li>
                <li>
                    <span class="name">QR Code</span>
                    <span class="name-text">A QR code value used by the IGNITE app for scanning fireworks.</span>
                </li>
                <li>
                    <span class="name">Barcode 1</span>
                    <span class="name-text">A barcode value used by the IGNITE app for scanning fireworks.</span>
                </li>
                <li>
                    <span class="name">Barcode 2</span>
                    <span class="name-text">A secondary barcode value used by the IGNITE app for scanning fireworks.</span>
                </li>
                <li>
                    <span class="name">Pro Use</span>
                    <span class="name-text">Options include <strong>Yes</strong> or <strong>No</strong>. Please use <strong>Yes</strong> for any Articles of Pyrotechnics 1.4 fireworks and <strong>No</strong> for traditional non-pro use consumer 1.4g fireworks. If no value is specified, this will default to <strong>No</strong>.</span>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-md-6">
        <div class="inner-tabs-main">
            <app-perform-a-new-import #performANewImportComponent></app-perform-a-new-import>
            <div class="inner-tabs">
                <div class="previous-btn">
                    <h2>Previous Imports</h2>
                    <p>You can review, download, and even revert previous imports below.</p>
                    <button (click)="openPreviousPopup()" mat-raised-button color="accent">Previous Imports</button>
                </div>
            </div>
            <div class="inner-tabs">
                <app-automated-import #automatedImport></app-automated-import>
            </div>
            <div class="inner-tabs">
                <div class="table-content">
                    <app-firework-types-mappings #fireworkTypesMappingsComponent></app-firework-types-mappings>
                </div>
            </div>
			<app-product-number-replacement #productNumberReplacementComponent></app-product-number-replacement>
        </div>
    </div>
</div>
