<h2>URL Based Automated Import</h2>
<p>Enter a URL that contains your fireworks import file.
    IGNITE will import this file nightly. This file will act as
    the master import file as any fireworks not within the import
    file will be deactivated on the IGNITE database. Any fireworks
    matching the part # field will be updated.</p>
<div class="tabs-email">
    <span class="tabs-email-inner">URL:</span>
    <p>
        <mat-form-field appearance="outline">
            <input matInput type="url" placeholder="e.g. http://www.mydomain.com/file.csv" [(ngModel)]="url">
            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
            <mat-hint>Enter the full URL of the file you wish to import</mat-hint>
        </mat-form-field>
    </p>
    <span class="tabs-email-inner">Email:</span>
    <p>
        <mat-form-field appearance="outline">
            <input matInput type="email" placeholder="Enter your email" [(ngModel)]="email">
            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
            <mat-hint>Import updates will be sent to this email address.</mat-hint>
        </mat-form-field>
    </p>
</div>
<div class="save-custom-btn">
    <button mat-raised-button color="primary" style="margin-right: 10px;" (click)="save()">Save</button>
    <button mat-raised-button color="accent" (click)="import()">Import Now</button>
</div>