import { Component } from '@angular/core';
import { BrandsService } from 'src/app/services/brands.service';
import { Brand } from 'src/app/interfaces/brand.interface';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-automated-import',
  templateUrl: './automated-import.component.html',
  styleUrls: ['./automated-import.component.scss']
})
export class AutomatedImportComponent {
  public loading = false;
  brand: Brand | undefined;
  url = ''
  email = ''

  constructor(private brandService: BrandsService,
    public _snakeBar: MatSnackBar){

  }

  init(brand: any){
    this.brand = brand;
    if(this.brand){
      if(this.brand.automated_import_url.url){
        this.url = this.brand.automated_import_url.url
      }
      if(this.brand.automated_import_url.email){
        this.email = this.brand.automated_import_url.email
      }
    }
  }
  save(){
    if(this.brand){
      this.loading = true;
      this.brandService.saveAutmaticCSV(this.brand.id, this.url, this.email).subscribe(() => {
        this.loading = false;
        if(this.brand){
          this.brand.automated_import_url = {
            url: this.url,
            email: this.email
          }
          this._snakeBar.open('The information is saved and will be processed midnight. Check back tomorrow.', 'Dismiss');
          setTimeout(() => {
            this._snakeBar.dismiss();
          }, 5000);
        }
      })
    }
  }
  import(){
    if(this.brand){
      this.loading = true;
      this.brandService.processAutmaticCSV(this.brand.id).subscribe(() => {
        this.loading = false;
        this._snakeBar.open('The CSV import is being processed in the background. Check back in a few minutes.', 'Dismiss');
        setTimeout(() => {
          this._snakeBar.dismiss();
        }, 5000);
      })
    }
  }
}
