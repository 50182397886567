
  // This file was autogenerated by dynamically running set-environment.ts and using dotenv for managing API key secrecy
  export const environment = {
    production: false,
    apiUrl: 'https://admin.ignitefiringsystems.com/api',
    appUrl: 'https://admin.ignitefiringsystems.com/app',
    wsUrl: 'undefined',
    pusherAppKey: 'undefined',
    pusherAppCluster: 'undefined',
    firebase: {
			apiKey: "AIzaSyDMmLcOgSXYa1fK4gBMbSpBpgtpZ-GUn9U",
			authDomain: "ignite-firing-systems-303723.firebaseapp.com",
			databaseURL: "https://ignite-firing-systems-303723-default-rtdb.firebaseio.com",
			projectId: "ignite-firing-systems-303723",
			storageBucket: "ignite-firing-systems-303723.appspot.com",
			messagingSenderId: "1086582487410",
			appId: "1:1086582487410:web:c5a374205decf80d2305eb",
			measurementId: "G-VBRLME905P",
			vapidKey: 'BAHctroTq3MqyYgmAAtqmk8T_pTtni39vgBKELxWzogpdM26y2z7PLxYtYOhlsjixCWkc8SuCoqYJGXgIlrdZZM'
		}
  };
